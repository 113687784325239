import React from "react";
import { observer } from "mobx-react-lite";
import { Anchor, Box, Image, Text } from "grommet";
import DocumentList from "./DocumentList";
import { ROUTES } from "../misc/routes";

const images = {
  sales_recap: require("../assets/sales_recap.png"),
  pm: require("../assets/pm.png"),
  trigonometry: require("../assets/trigonometry.png")
};

function Dashboard() {
  return (
    <Box
      fill="vertical"
      align="center"
      height={{ min: "auto" }}
      pad={{ vertical: "medium" }}
    >
      <Box
        margin={{ top: "medium" }}
        fill="horizontal"
        width={{ max: "large" }}
      >
        <Text>Create new document from template: </Text>
        <Box direction="row" margin={{ top: "xsmall", bottom: "medium" }}>
          {[
            { label: "Monthly Sales Recap", tmpl: "sales_recap" },
            { label: "Project Management", tmpl: "pm" },
            { label: "Trigonometry", tmpl: "trigonometry" }
          ].map(({ label, tmpl }, i) => (
            <Anchor
              key={i}
              margin={{ left: i !== 0 ? "small" : "none" }}
              href={`${ROUTES.createDoc}?tmpl=${tmpl}`}
            >
              <Image width={140} height={190} src={images[tmpl]} />
            </Anchor>
          ))}
        </Box>
        <Text margin={{ bottom: "small" }}>Documents: </Text>
        <DocumentList />
      </Box>
    </Box>
  );
}

export default observer(Dashboard);
