export const ROUTES = {
  root: "/",
  doc: "/doc/:docId",
  createDoc: "/new-doc",
  sharedDoc: "/shared/:docId",
  gdriveCreate: "/gdrive/new",
  gdriveOpen: "/gdrive/open",
  signUp: "/signup",
  signIn: "/signin",
  account: "/account",
  passwordForget: "/pw-forget"
};

export const prepareRoute = (route = "", params = {}) => {
  return Object.entries(params).reduce((acc, [param, value]) => {
    return acc.replace(`:${param}`, value);
  }, route);
};
