import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Button, Header as GrommetHeader, Box, Text, Menu } from "grommet";
import { ROUTES } from "../misc/routes";
// import { prepareRoute, ROUTES } from "../misc/routes";
import { useStore } from "../store";

function Header() {
  const history = useHistory();
  const { documentStore, userStore } = useStore();

  /*
  const openPickerOpenDoc = async () => {
    const docId = await documentStore.pickDocument();

    if (!docId) {
      return;
    }

    history.push(prepareRoute(ROUTES.doc, { docId }));
  };
  */

  const openPickerCreateDoc = async () => {
    const folderId = await documentStore.pickFolder();

    // ToDo: handle parent folder in dataSource
    history.push(
      `${ROUTES.createDoc}${folderId ? `?folderId=${folderId}` : ""}`
    );
  };

  return (
    <GrommetHeader
      background="brand"
      pad={{ horizontal: "medium", vertical: "xsmall" }}
    >
      <Box direction="row" align="baseline">
        <Button>
          <Box
            pad="small"
            direction="row"
            align="center"
            gap="small"
            round="medium"
            onClick={() => history.push(`${ROUTES.root}`)}
          >
            <Text weight={600}>reactivepad</Text>
          </Box>
        </Button>
        {userStore.isAuthenticated && (
          <Fragment>
            <Button
              primary
              margin={{ left: "large" }}
              size="small"
              label="Create document"
              onClick={openPickerCreateDoc}
            />
            {/*<Button
              margin={{ left: "small" }}
              size="small"
              label="Open document"
              onClick={openPickerOpenDoc}
            />*/}
          </Fragment>
        )}
      </Box>
      {userStore.isAuthenticated && (
        <Box direction="row" gap="medium">
          <Menu
            dropProps={{ align: { top: "bottom", left: "left" } }}
            label="menu"
            items={[
              {
                label: "Logout",
                onClick: () => userStore.logout()
              }
            ]}
          />
        </Box>
      )}
    </GrommetHeader>
  );
}

export default observer(Header);
