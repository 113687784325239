import "firebase/storage";
import { store } from "./index";
import { generateId } from "./misc/utils";
import { DOC_FILE_EXTENSION_WITH_DOT } from "./misc/common";
import { firebase } from "./authProvider3";
import { get, isNil } from "lodash-es";

export const PERMISSIONS = {
  anyone: "anyone",
  anyoneWithLink: "anyoneWithLink"
};

export const firebaseStorage = firebase.storage();
const storageRef = firebaseStorage.ref();

const getRef = path =>
  storageRef.child(`users/${store.userStore.user.uid}/${path}`);

export default {
  PERMISSIONS,
  getDocuments: async () => {
    const docs = await getRef("")
      .listAll()
      .then(({ items }) => {
        return Promise.all(items.map(itemRef => itemRef.getMetadata()));
      })
      .catch(function(error) {
        console.log("err", error);
      });

    return docs.map(({ name, customMetadata, updated }) => ({
      id: name,
      name: customMetadata.fileName,
      modifiedTime: updated
    }));
  },
  createDocument: (
    { name = generateId(), parents = [], ...metaData } = {},
    content
  ) => {
    const customMetadata = {
      fileName: `Untitled${DOC_FILE_EXTENSION_WITH_DOT}`,
      ...metaData
    };

    return getRef(name)
      .putString(isNil(content) ? "" : JSON.stringify(content), undefined, {
        customMetadata
      })
      .then(() => ({
        id: name
      }));
  },
  getDocument: async id => {
    try {
      const { updated, customMetadata } = await getRef(id).getMetadata();
      const downloadUrl = await getRef(id).getDownloadURL();
      const requestFile = await fetch(downloadUrl);
      const text = await requestFile.text();

      const content = isNil(text) || text === "" ? null : JSON.parse(text);

      if (!!get(content, "error")) {
        return Promise.reject(content);
      }

      return Promise.resolve({
        id,
        name: customMetadata.fileName,
        modifiedTime: updated,
        content
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  updateDocument: async (docId, data = {}) => {
    try {
      const stringified = JSON.stringify(data);
      const docRef = getRef(docId);
      const { customMetadata } = await docRef.getMetadata();
      const result = await docRef.putString(stringified);
      await docRef.updateMetadata({ customMetadata });

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  renameDocument: async (docId, name) => {
    try {
      const docRef = getRef(docId);
      const { customMetadata } = await docRef.getMetadata();

      return docRef.updateMetadata({
        customMetadata: { ...customMetadata, fileName: name }
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  createPermission: () => {
    return Promise.resolve({
      id: PERMISSIONS.anyoneWithLink
    });
  },
  deletePermission: () => {
    return Promise.resolve();
  },
  setup: () => {
    return Promise.resolve();
  },
  pickDocument: async () => {
    return Promise.resolve();
  },
  pickFolder: async () => {
    return Promise.resolve();
  }
};
