import { gapi } from "gapi-script";

export const CLIENT_ID =
  "1043917107049-9j2t9sa1665pbj13vupthd7jbif63ia8.apps.googleusercontent.com";
export const API_KEY = "AIzaSyBoYGCBVfCuAlEcpK_zsbn_ClHdwHuUwuQ";
export const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
];
export const SCOPES = [
  "https://www.googleapis.com/auth/drive",
  "https://www.googleapis.com/auth/drive.install"
];

const setup = () =>
  new Promise(resolve => {
    gapi.load("client:auth2:picker", async () => {
      await gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES.join(" ")
      });
      resolve();
    });
  });

const parseUser = user =>
  !user.getId
    ? user
    : {
        id: user.getId(),
        name: user.getName(),
        givenName: user.getGivenName(),
        familyName: user.getFamilyName(),
        imageUrl: user.getImageUrl(),
        email: user.getEmail()
      };

export const tempProvider = {
  login: async () => {
    const authData = await gapi.auth2.getAuthInstance().signIn();

    return Promise.resolve({
      authData: authData.getAuthResponse(),
      user: parseUser(authData.getBasicProfile())
    });
  },
  logout: () => {
    return gapi.auth2.getAuthInstance().signOut();
  },
  checkAuth: async () => {
    await setup();
    const auth2 = gapi.auth2.getAuthInstance();
    if (auth2.isSignedIn.get()) {
      const currentUser = auth2.currentUser.get();

      return Promise.resolve({
        authData: currentUser.getAuthResponse(),
        user: parseUser(currentUser.getBasicProfile())
      });
    }

    return Promise.reject(new Error("checkAuth error"));
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve()
};

export default {
  login: async () => {
    const authData = await gapi.auth2.getAuthInstance().signIn();
    return Promise.resolve(authData.getAuthResponse());
  },
  logout: () => {
    return gapi.auth2.getAuthInstance().signOut();
  },
  checkAuth: async () => {
    try {
      await setup();
      const auth2 = gapi.auth2.getAuthInstance();
      if (auth2.isSignedIn.get()) {
        return Promise.resolve(auth2.currentUser.get().getAuthResponse());
      }

      return Promise.reject(new Error("checkAuth error"));
    } catch (e) {
      return Promise.reject(e);
    }
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve()
};
