export const sales_recap = {
  doc: {
    type: "doc",
    content: [
      {
        type: "heading",
        attrs: { level: 1 },
        content: [{ type: "text", text: "Stats" }]
      },
      {
        type: "heading",
        attrs: { level: 3 },
        content: [
          { type: "hard_break" },
          { type: "text", text: "January, 2019 ⌚️" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Beginning of month Total MRR: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "neioo",
              type: "formula",
              name: "start_mrr",
              value: 20000,
              formatted: "20,000",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "New MRR: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "ihkmd",
              type: "formula",
              name: "new_mrr",
              value: 2500,
              formatted: "2,500",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Churn and Downgrades: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "rupzm",
              type: "formula",
              name: "churn",
              value: 300,
              formatted: 300,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Upgrades: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "flabt",
              type: "formula",
              name: "upgrades",
              value: 500,
              formatted: 500,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Net New MRR: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "httro",
              type: "formula",
              name: "net_mrr",
              value: "=new_mrr+upgrades-churn",
              formatted: "2,700",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "End of month Total MRR: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "tojvq",
              type: "formula",
              name: "total_mrr",
              value: "=start_mrr+net_mrr",
              formatted: "22,700",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " $" }
        ]
      },
      {
        type: "paragraph",
        content: [{ type: "text", text: "New Customers: 24" }]
      },
      { type: "paragraph", content: [{ type: "text", text: "Upgrades: 3" }] },
      { type: "paragraph" },
      {
        type: "paragraph",
        content: [
          { type: "text", marks: [{ type: "em" }], text: "Notes" },
          { type: "text", text: ":" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Last month was closed with MRR " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "qsdqk",
              type: "formula",
              name: "formula1",
              value: "=total_mrr",
              formatted: "22,700",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " $ which is" },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "gqttq",
              type: "formula",
              name: "formula2",
              value: "=net_mrr",
              formatted: 2700,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          {
            type: "text",
            text: " $ higher than last year. The growth rate is "
          },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "gufcj",
              type: "formula",
              name: "growth",
              value: "=net_mrr/total_mrr",
              formatted: "12%",
              formatter: "0%",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "." }
        ]
      },
      { type: "paragraph" },
      {
        type: "heading",
        attrs: { level: 3 },
        content: [{ type: "text", text: "Goal 🥅" }]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Goal: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "hlyfk",
              type: "formula",
              name: "quater_goal",
              value: 2000,
              formatted: "2,000",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Net New MRR: $ Percent of goal: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "esdoq",
              type: "formula",
              name: "net_new",
              value: "=net_mrr/quater_goal",
              formatted: "135%",
              formatter: "0%",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " },
          { type: "hard_break" },
          { type: "text", text: "Quarterly goal " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "cnpol",
              type: "formula",
              name: "quarter_g",
              value: 12000,
              formatted: "12,000",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  $" }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Percentage of quarterly goal: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "ojuzn",
              type: "formula",
              name: "quar_perc",
              value: "=net_mrr/quarter_g",
              formatted: "23%",
              formatter: "0%",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " }
        ]
      },
      { type: "paragraph" },
      {
        type: "paragraph",
        content: [
          { type: "text", marks: [{ type: "em" }], text: "Notes" },
          { type: "text", text: ":" }
        ]
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "After the first month of 2019, we already achieved "
          },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "gsawx",
              type: "formula",
              name: "formula3",
              value: "=quar_perc",
              formatted: "23%",
              formatter: "0%",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  of our quarter goal." },
          { type: "hard_break" }
        ]
      },
      {
        type: "heading",
        attrs: { level: 3 },
        content: [{ type: "text", text: "Funnel Metrics 📈" }]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Traffic: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "zkmik",
              type: "formula",
              name: "traffic",
              value: 12000,
              formatted: "12,000",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "MQLs: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "vqhxm",
              type: "formula",
              name: "mql",
              value: "=100",
              formatted: "100",
              formatter: "0,0",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  " }
        ]
      },
      {
        type: "bullet_list",
        content: [
          {
            type: "list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  { type: "text", text: "conversion: " },
                  {
                    type: "reactivepad_formula",
                    attrs: {
                      id: "glvxe",
                      type: "formula",
                      name: "trafficmql",
                      value: "=mql/traffic",
                      formatted: "1%",
                      formatter: "0%",
                      fieldType: "plain",
                      fieldMetaData: null
                    }
                  },
                  { type: "text", text: " " }
                ]
              }
            ]
          }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "SQLs: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "ouknb",
              type: "formula",
              name: "sql",
              value: 35,
              formatted: 35,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  " }
        ]
      },
      {
        type: "bullet_list",
        content: [
          {
            type: "list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  { type: "text", text: "conversion:  " },
                  {
                    type: "reactivepad_formula",
                    attrs: {
                      id: "znfvp",
                      type: "formula",
                      name: "formula4",
                      value: "=sql/mql",
                      formatted: "35%",
                      formatter: "0%",
                      fieldType: "plain",
                      fieldMetaData: null
                    }
                  },
                  { type: "text", text: " " }
                ]
              }
            ]
          }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Closed Won Deals: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "gwzgs",
              type: "formula",
              name: "won",
              value: 24,
              formatted: 24,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  " }
        ]
      },
      {
        type: "bullet_list",
        content: [
          {
            type: "list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  { type: "text", text: "conversion:  " },
                  {
                    type: "reactivepad_formula",
                    attrs: {
                      id: "pdxnz",
                      type: "formula",
                      name: "wonsql",
                      value: "=won/sql",
                      formatted: "69%",
                      formatter: "0%",
                      fieldType: "plain",
                      fieldMetaData: null
                    }
                  },
                  { type: "text", text: " " },
                  { type: "hard_break" },
                  { type: "hard_break" },
                  { type: "text", marks: [{ type: "em" }], text: "Notes" },
                  { type: "text", text: ":" }
                ]
              },
              {
                type: "paragraph",
                content: [
                  { type: "text", text: "This month we won " },
                  {
                    type: "reactivepad_formula",
                    attrs: {
                      id: "dbfpq",
                      type: "formula",
                      name: "wonwon",
                      value: "=won",
                      formatted: 24,
                      formatter: null,
                      fieldType: "plain",
                      fieldMetaData: null
                    }
                  },
                  {
                    type: "text",
                    text: " deals and acquired record traffic ever, which is "
                  },
                  {
                    type: "reactivepad_formula",
                    attrs: {
                      id: "rqjdb",
                      type: "formula",
                      name: "formula5",
                      value: "=traffic",
                      formatted: 12000,
                      formatter: null,
                      fieldType: "plain",
                      fieldMetaData: null
                    }
                  },
                  { type: "text", text: " ." }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  selection: { type: "text", anchor: 6, head: 8 },
  reactivepad_prosemirror$: {
    id: "crtzq",
    nodes: [
      {
        id: "neioo",
        type: "formula",
        name: "start_mrr",
        value: 20000,
        formatted: "20,000",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "ihkmd",
        type: "formula",
        name: "new_mrr",
        value: 2500,
        formatted: "2,500",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "rupzm",
        type: "formula",
        name: "churn",
        value: 300,
        formatted: 300,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "flabt",
        type: "formula",
        name: "upgrades",
        value: 500,
        formatted: 500,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "httro",
        type: "formula",
        name: "net_mrr",
        value: "=new_mrr+upgrades-churn",
        formatted: "2,700",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "tojvq",
        type: "formula",
        name: "total_mrr",
        value: "=start_mrr+net_mrr",
        formatted: "22,700",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "qsdqk",
        type: "formula",
        name: "formula1",
        value: "=total_mrr",
        formatted: "22,700",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "gqttq",
        type: "formula",
        name: "formula2",
        value: "=net_mrr",
        formatted: 2700,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "gufcj",
        type: "formula",
        name: "growth",
        value: "=net_mrr/total_mrr",
        formatted: "12%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "hlyfk",
        type: "formula",
        name: "quater_goal",
        value: 2000,
        formatted: "2,000",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "esdoq",
        type: "formula",
        name: "net_new",
        value: "=net_mrr/quater_goal",
        formatted: "135%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "cnpol",
        type: "formula",
        name: "quarter_g",
        value: 12000,
        formatted: "12,000",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "ojuzn",
        type: "formula",
        name: "quar_perc",
        value: "=net_mrr/quarter_g",
        formatted: "23%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "gsawx",
        type: "formula",
        name: "formula3",
        value: "=quar_perc",
        formatted: "23%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "zkmik",
        type: "formula",
        name: "traffic",
        value: 12000,
        formatted: "12,000",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "vqhxm",
        type: "formula",
        name: "mql",
        value: "=100",
        formatted: "100",
        formatter: "0,0",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "glvxe",
        type: "formula",
        name: "trafficmql",
        value: "=mql/traffic",
        formatted: "1%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "ouknb",
        type: "formula",
        name: "sql",
        value: 35,
        formatted: 35,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "znfvp",
        type: "formula",
        name: "formula4",
        value: "=sql/mql",
        formatted: "35%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "gwzgs",
        type: "formula",
        name: "won",
        value: 24,
        formatted: 24,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "pdxnz",
        type: "formula",
        name: "wonsql",
        value: "=won/sql",
        formatted: "69%",
        formatter: "0%",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "dbfpq",
        type: "formula",
        name: "wonwon",
        value: "=won",
        formatted: 24,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "rqjdb",
        type: "formula",
        name: "formula5",
        value: "=traffic",
        formatted: 12000,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      }
    ],
    dataSources: [],
    dataSourceProvider: null,
    readOnly: false
  }
};

export const pm = {
  doc: {
    type: "doc",
    content: [
      {
        type: "heading",
        attrs: { level: 2 },
        content: [{ type: "text", text: "Introduction" }]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Launching version 2.0 of our XYZ product." }
        ]
      },
      {
        type: "heading",
        attrs: { level: 4 },
        content: [{ type: "text", text: "Themes" }]
      },
      {
        type: "bullet_list",
        content: [
          {
            type: "list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text:
                      "Great company-wide effort to work towards a common goal"
                  }
                ]
              }
            ]
          },
          {
            type: "list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text:
                      "Could've done a better job with getting reporters lined up for launch"
                  }
                ]
              }
            ]
          },
          {
            type: "list_item",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    type: "text",
                    text:
                      "We launched most features with ample time for testing, but should allow more time for testing"
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: "heading",
        attrs: { level: 4 },
        content: [{ type: "text", text: "Tasks" }, { type: "hard_break" }]
      },
      {
        type: "reactivepad_table",
        attrs: {
          id: "xywhe",
          type: "table",
          name: "tasks",
          columns: [
            {
              id: "dpmzc",
              name: "Person",
              type: "column",
              columnValue: null,
              footerValue: null,
              footerFormatted: "",
              formatter: null,
              columnCellsType: null,
              width: 150
            },
            {
              id: "ymfax",
              name: "Task",
              type: "column",
              columnValue: null,
              footerValue: null,
              footerFormatted: "",
              formatter: null,
              columnCellsType: null,
              width: 398
            },
            {
              id: "wwxpf",
              name: "Status",
              type: "column",
              columnValue: null,
              footerValue: null,
              footerFormatted: "",
              formatter: null,
              columnCellsType: {
                type: "select",
                config: {
                  values: [
                    { value: "done" },
                    { value: "onhold" },
                    { value: "to-do" },
                    { value: "on-hold" }
                  ]
                }
              },
              width: 150
            }
          ],
          rows: [
            {
              id: "phfjh",
              type: "row",
              cells: [
                {
                  id: "mcakc",
                  type: "cell",
                  column: "dpmzc",
                  value: "John",
                  formatted: "John"
                },
                {
                  id: "vqhen",
                  type: "cell",
                  column: "ymfax",
                  value: "Set stretch goals",
                  formatted: "Set stretch goals"
                },
                {
                  id: "bazpt",
                  type: "cell",
                  column: "wwxpf",
                  value: "done",
                  formatted: "done"
                }
              ],
              height: 27
            },
            {
              id: "pppzy",
              type: "row",
              cells: [
                {
                  id: "rjaoi",
                  type: "cell",
                  column: "dpmzc",
                  value: "Felix",
                  formatted: "Felix"
                },
                {
                  id: "hauhk",
                  type: "cell",
                  column: "ymfax",
                  value: "Develop stories with reporters",
                  formatted: "Develop stories with reporters"
                },
                {
                  id: "nryiq",
                  type: "cell",
                  column: "wwxpf",
                  value: "on-hold",
                  formatted: "on-hold"
                }
              ],
              height: 27
            },
            {
              id: "bhzwl",
              type: "row",
              cells: [
                {
                  id: "hngds",
                  type: "cell",
                  column: "dpmzc",
                  value: "John",
                  formatted: "John"
                },
                {
                  id: "simtl",
                  type: "cell",
                  column: "ymfax",
                  value:
                    "Consult with product team to figure out launching messages",
                  formatted:
                    "Consult with product team to figure out launching messages"
                },
                {
                  id: "iljou",
                  type: "cell",
                  column: "wwxpf",
                  value: "done",
                  formatted: "done"
                }
              ],
              height: 27
            },
            {
              id: "plllq",
              type: "row",
              cells: [
                {
                  id: "zzjet",
                  type: "cell",
                  column: "dpmzc",
                  value: "Andrew",
                  formatted: "Andrew"
                },
                {
                  id: "qthyy",
                  type: "cell",
                  column: "ymfax",
                  value:
                    "Communicate to the whole company about new features development",
                  formatted:
                    "Communicate to the whole company about new features development"
                },
                {
                  id: "rcawg",
                  type: "cell",
                  column: "wwxpf",
                  value: "to-do",
                  formatted: "to-do"
                }
              ],
              height: 27
            },
            {
              id: "kuzwq",
              type: "row",
              cells: [
                {
                  id: "taxzj",
                  type: "cell",
                  column: "dpmzc",
                  value: "Felix",
                  formatted: "Felix"
                },
                {
                  id: "jfpsp",
                  type: "cell",
                  column: "ymfax",
                  value: "Make the page navigation delightful",
                  formatted: "Make the page navigation delightful"
                },
                {
                  id: "rhwoq",
                  type: "cell",
                  column: "wwxpf",
                  value: "on-hold",
                  formatted: "on-hold"
                }
              ],
              height: 27
            },
            {
              id: "wwqwj",
              type: "row",
              cells: [
                {
                  id: "erfqd",
                  type: "cell",
                  column: "dpmzc",
                  value: "Katie",
                  formatted: "Katie"
                },
                {
                  id: "wqgbp",
                  type: "cell",
                  column: "ymfax",
                  value: "Prepare knowledgebase",
                  formatted: "Prepare knowledgebase"
                },
                {
                  id: "iwmnl",
                  type: "cell",
                  column: "wwxpf",
                  value: "done",
                  formatted: "done"
                }
              ],
              height: 27
            }
          ],
          showFooter: false
        }
      },
      { type: "paragraph" },
      {
        type: "heading",
        attrs: { level: 3 },
        content: [
          { type: "text", text: "Tasks accomplished: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "zqzeb",
              type: "formula",
              name: "formula1",
              value: '=COUNTIF(tasks[Status],"done")',
              formatted: 3,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: "  / " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "syhei",
              type: "formula",
              name: "formula2",
              value: "=ROWS(tasks[Task])",
              formatted: 1,
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " }
        ]
      },
      {
        type: "heading",
        attrs: { level: 3 },
        content: [
          { type: "text", text: "People who have completed their tasks: " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "qxcch",
              type: "formula",
              name: "formula3",
              value:
                '= JOIN(FILTER(tasks[Person], tasks[Status], "done"), ", ")',
              formatted: "John, John, Katie",
              formatter: null,
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " }
        ]
      }
    ]
  },
  selection: { type: "text", anchor: 306, head: 306 },
  reactivepad_prosemirror$: {
    id: "zecdo",
    nodes: [
      {
        id: "zqzeb",
        type: "formula",
        name: "formula1",
        value: '=COUNTIF(tasks[Status],"done")',
        formatted: 3,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "xywhe",
        type: "table",
        name: "tasks",
        columns: [
          {
            id: "dpmzc",
            name: "Person",
            type: "column",
            columnValue: null,
            footerValue: null,
            footerFormatted: "",
            formatter: null,
            columnCellsType: null,
            width: 150
          },
          {
            id: "ymfax",
            name: "Task",
            type: "column",
            columnValue: null,
            footerValue: null,
            footerFormatted: "",
            formatter: null,
            columnCellsType: null,
            width: 398
          },
          {
            id: "wwxpf",
            name: "Status",
            type: "column",
            columnValue: null,
            footerValue: null,
            footerFormatted: "",
            formatter: null,
            columnCellsType: {
              type: "select",
              config: {
                values: [
                  { value: "done" },
                  { value: "onhold" },
                  { value: "to-do" },
                  { value: "on-hold" }
                ]
              }
            },
            width: 150
          }
        ],
        rows: [
          {
            id: "phfjh",
            type: "row",
            cells: [
              {
                id: "mcakc",
                type: "cell",
                column: "dpmzc",
                value: "John",
                formatted: "John"
              },
              {
                id: "vqhen",
                type: "cell",
                column: "ymfax",
                value: "Set stretch goals",
                formatted: "Set stretch goals"
              },
              {
                id: "bazpt",
                type: "cell",
                column: "wwxpf",
                value: "done",
                formatted: "done"
              }
            ],
            height: 27
          },
          {
            id: "pppzy",
            type: "row",
            cells: [
              {
                id: "rjaoi",
                type: "cell",
                column: "dpmzc",
                value: "Felix",
                formatted: "Felix"
              },
              {
                id: "hauhk",
                type: "cell",
                column: "ymfax",
                value: "Develop stories with reporters",
                formatted: "Develop stories with reporters"
              },
              {
                id: "nryiq",
                type: "cell",
                column: "wwxpf",
                value: "on-hold",
                formatted: "on-hold"
              }
            ],
            height: 27
          },
          {
            id: "bhzwl",
            type: "row",
            cells: [
              {
                id: "hngds",
                type: "cell",
                column: "dpmzc",
                value: "John",
                formatted: "John"
              },
              {
                id: "simtl",
                type: "cell",
                column: "ymfax",
                value:
                  "Consult with product team to figure out launching messages",
                formatted:
                  "Consult with product team to figure out launching messages"
              },
              {
                id: "iljou",
                type: "cell",
                column: "wwxpf",
                value: "done",
                formatted: "done"
              }
            ],
            height: 27
          },
          {
            id: "plllq",
            type: "row",
            cells: [
              {
                id: "zzjet",
                type: "cell",
                column: "dpmzc",
                value: "Andrew",
                formatted: "Andrew"
              },
              {
                id: "qthyy",
                type: "cell",
                column: "ymfax",
                value:
                  "Communicate to the whole company about new features development",
                formatted:
                  "Communicate to the whole company about new features development"
              },
              {
                id: "rcawg",
                type: "cell",
                column: "wwxpf",
                value: "to-do",
                formatted: "to-do"
              }
            ],
            height: 27
          },
          {
            id: "kuzwq",
            type: "row",
            cells: [
              {
                id: "taxzj",
                type: "cell",
                column: "dpmzc",
                value: "Felix",
                formatted: "Felix"
              },
              {
                id: "jfpsp",
                type: "cell",
                column: "ymfax",
                value: "Make the page navigation delightful",
                formatted: "Make the page navigation delightful"
              },
              {
                id: "rhwoq",
                type: "cell",
                column: "wwxpf",
                value: "on-hold",
                formatted: "on-hold"
              }
            ],
            height: 27
          },
          {
            id: "wwqwj",
            type: "row",
            cells: [
              {
                id: "erfqd",
                type: "cell",
                column: "dpmzc",
                value: "Katie",
                formatted: "Katie"
              },
              {
                id: "wqgbp",
                type: "cell",
                column: "ymfax",
                value: "Prepare knowledgebase",
                formatted: "Prepare knowledgebase"
              },
              {
                id: "iwmnl",
                type: "cell",
                column: "wwxpf",
                value: "done",
                formatted: "done"
              }
            ],
            height: 27
          }
        ],
        showFooter: false
      },
      {
        id: "syhei",
        type: "formula",
        name: "formula2",
        value: "=ROWS(tasks[Task])",
        formatted: 1,
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "qxcch",
        type: "formula",
        name: "formula3",
        value: '= JOIN(FILTER(tasks[Person], tasks[Status], "done"), ", ")',
        formatted: "John, John, Katie",
        formatter: null,
        fieldType: "plain",
        fieldMetaData: null
      }
    ],
    dataSources: [],
    dataSourceProvider: null,
    readOnly: false
  }
};

export const trigonometry = {
  doc: {
    type: "doc",
    content: [
      {
        type: "heading",
        attrs: { level: 1 },
        content: [
          {
            type: "text",
            text:
              "Trigonometric ratios are the ratios between edges of a right triangle. These ratios are given by the trigonometric functions of the know angle A, where a, b, and c refer to the lengths of the sides in the accompanying figure."
          }
        ]
      },
      { type: "paragraph" },
      {
        type: "paragraph",
        content: [
          { type: "text", marks: [{ type: "strong" }], text: "parameter" },
          { type: "text", text: ": " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "ucewk",
              type: "formula",
              name: "param",
              value: 14,
              formatted: 14,
              formatter: null,
              fieldType: "slider",
              fieldMetaData: { min: 0, max: 50, step: 1 }
            }
          },
          { type: "text", text: " " }
        ]
      },
      {
        type: "paragraph",
        content: [
          { type: "text", marks: [{ type: "strong" }], text: "x range" },
          { type: "text", text: ": " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "ioyyr",
              type: "formula",
              name: "xAxis",
              value: "= RANGE(-50, 50)",
              formatted: [
                "-50.00",
                "-49.00",
                "-48.00",
                "-47.00",
                "-46.00",
                "-45.00",
                "-44.00",
                "-43.00",
                "-42.00",
                "-41.00",
                "-40.00",
                "-39.00",
                "-38.00",
                "-37.00",
                "-36.00",
                "-35.00",
                "-34.00",
                "-33.00",
                "-32.00",
                "-31.00",
                "-30.00",
                "-29.00",
                "-28.00",
                "-27.00",
                "-26.00",
                "-25.00",
                "-24.00",
                "-23.00",
                "-22.00",
                "-21.00",
                "-20.00",
                "-19.00",
                "-18.00",
                "-17.00",
                "-16.00",
                "-15.00",
                "-14.00",
                "-13.00",
                "-12.00",
                "-11.00",
                "-10.00",
                "-9.00",
                "-8.00",
                "-7.00",
                "-6.00",
                "-5.00",
                "-4.00",
                "-3.00",
                "-2.00",
                "-1.00",
                "0.00",
                "1.00",
                "2.00",
                "3.00",
                "4.00",
                "5.00",
                "6.00",
                "7.00",
                "8.00",
                "9.00",
                "10.00",
                "11.00",
                "12.00",
                "13.00",
                "14.00",
                "15.00",
                "16.00",
                "17.00",
                "18.00",
                "19.00",
                "20.00",
                "21.00",
                "22.00",
                "23.00",
                "24.00",
                "25.00",
                "26.00",
                "27.00",
                "28.00",
                "29.00",
                "30.00",
                "31.00",
                "32.00",
                "33.00",
                "34.00",
                "35.00",
                "36.00",
                "37.00",
                "38.00",
                "39.00",
                "40.00",
                "41.00",
                "42.00",
                "43.00",
                "44.00",
                "45.00",
                "46.00",
                "47.00",
                "48.00",
                "49.00"
              ],
              formatter: "0,0.00",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " }
        ]
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "sin(x/parameter)"
          },
          { type: "text", text: ": " },
          {
            type: "reactivepad_formula",
            attrs: {
              id: "ezzxp",
              type: "formula",
              name: "sinus",
              value: '=ARRAYFORMULA("SIN",ARRAYFORMULA("DIVIDE",xAxis,param))',
              formatted: [
                "0.42",
                "0.35",
                "0.28",
                "0.21",
                "0.14",
                "0.07",
                "0.00",
                "-0.07",
                "-0.14",
                "-0.21",
                "-0.28",
                "-0.35",
                "-0.41",
                "-0.48",
                "-0.54",
                "-0.60",
                "-0.65",
                "-0.71",
                "-0.76",
                "-0.80",
                "-0.84",
                "-0.88",
                "-0.91",
                "-0.94",
                "-0.96",
                "-0.98",
                "-0.99",
                "-1.00",
                "-1.00",
                "-1.00",
                "-0.99",
                "-0.98",
                "-0.96",
                "-0.94",
                "-0.91",
                "-0.88",
                "-0.84",
                "-0.80",
                "-0.76",
                "-0.71",
                "-0.66",
                "-0.60",
                "-0.54",
                "-0.48",
                "-0.42",
                "-0.35",
                "-0.28",
                "-0.21",
                "-0.14",
                "-0.07",
                "0.00",
                "0.07",
                "0.14",
                "0.21",
                "0.28",
                "0.35",
                "0.42",
                "0.48",
                "0.54",
                "0.60",
                "0.66",
                "0.71",
                "0.76",
                "0.80",
                "0.84",
                "0.88",
                "0.91",
                "0.94",
                "0.96",
                "0.98",
                "0.99",
                "1.00",
                "1.00",
                "1.00",
                "0.99",
                "0.98",
                "0.96",
                "0.94",
                "0.91",
                "0.88",
                "0.84",
                "0.80",
                "0.76",
                "0.71",
                "0.65",
                "0.60",
                "0.54",
                "0.48",
                "0.41",
                "0.35",
                "0.28",
                "0.21",
                "0.14",
                "0.07",
                "0.00",
                "-0.07",
                "-0.14",
                "-0.21",
                "-0.28",
                "-0.35"
              ],
              formatter: "0,0.00",
              fieldType: "plain",
              fieldMetaData: null
            }
          },
          { type: "text", text: " " }
        ]
      },
      { type: "paragraph" },
      {
        type: "reactivepad_chart",
        attrs: {
          id: "segon",
          type: "chart",
          chartType: "line",
          dataRefs: { xAxis: ["sgqyu"], series: [["ezzxp"]] }
        }
      },
      { type: "paragraph" }
    ]
  },
  selection: { type: "text", anchor: 280, head: 280 },
  reactivepad_prosemirror$: {
    id: "yecyj",
    nodes: [
      {
        id: "ezzxp",
        type: "formula",
        name: "sinus",
        value: '=ARRAYFORMULA("SIN",ARRAYFORMULA("DIVIDE",xAxis,param))',
        formatted: [
          "0.42",
          "0.35",
          "0.28",
          "0.21",
          "0.14",
          "0.07",
          "0.00",
          "-0.07",
          "-0.14",
          "-0.21",
          "-0.28",
          "-0.35",
          "-0.41",
          "-0.48",
          "-0.54",
          "-0.60",
          "-0.65",
          "-0.71",
          "-0.76",
          "-0.80",
          "-0.84",
          "-0.88",
          "-0.91",
          "-0.94",
          "-0.96",
          "-0.98",
          "-0.99",
          "-1.00",
          "-1.00",
          "-1.00",
          "-0.99",
          "-0.98",
          "-0.96",
          "-0.94",
          "-0.91",
          "-0.88",
          "-0.84",
          "-0.80",
          "-0.76",
          "-0.71",
          "-0.66",
          "-0.60",
          "-0.54",
          "-0.48",
          "-0.42",
          "-0.35",
          "-0.28",
          "-0.21",
          "-0.14",
          "-0.07",
          "0.00",
          "0.07",
          "0.14",
          "0.21",
          "0.28",
          "0.35",
          "0.42",
          "0.48",
          "0.54",
          "0.60",
          "0.66",
          "0.71",
          "0.76",
          "0.80",
          "0.84",
          "0.88",
          "0.91",
          "0.94",
          "0.96",
          "0.98",
          "0.99",
          "1.00",
          "1.00",
          "1.00",
          "0.99",
          "0.98",
          "0.96",
          "0.94",
          "0.91",
          "0.88",
          "0.84",
          "0.80",
          "0.76",
          "0.71",
          "0.65",
          "0.60",
          "0.54",
          "0.48",
          "0.41",
          "0.35",
          "0.28",
          "0.21",
          "0.14",
          "0.07",
          "0.00",
          "-0.07",
          "-0.14",
          "-0.21",
          "-0.28",
          "-0.35"
        ],
        formatter: "0,0.00",
        fieldType: "plain",
        fieldMetaData: null
      },
      {
        id: "segon",
        type: "chart",
        chartType: "line",
        dataRefs: { xAxis: ["sgqyu"], series: [["ezzxp"]] }
      },
      {
        id: "ucewk",
        type: "formula",
        name: "param",
        value: 14,
        formatted: 14,
        formatter: null,
        fieldType: "slider",
        fieldMetaData: { min: 0, max: 50, step: 1 }
      },
      {
        id: "ioyyr",
        type: "formula",
        name: "xAxis",
        value: "= RANGE(-50, 50)",
        formatted: [
          "-50.00",
          "-49.00",
          "-48.00",
          "-47.00",
          "-46.00",
          "-45.00",
          "-44.00",
          "-43.00",
          "-42.00",
          "-41.00",
          "-40.00",
          "-39.00",
          "-38.00",
          "-37.00",
          "-36.00",
          "-35.00",
          "-34.00",
          "-33.00",
          "-32.00",
          "-31.00",
          "-30.00",
          "-29.00",
          "-28.00",
          "-27.00",
          "-26.00",
          "-25.00",
          "-24.00",
          "-23.00",
          "-22.00",
          "-21.00",
          "-20.00",
          "-19.00",
          "-18.00",
          "-17.00",
          "-16.00",
          "-15.00",
          "-14.00",
          "-13.00",
          "-12.00",
          "-11.00",
          "-10.00",
          "-9.00",
          "-8.00",
          "-7.00",
          "-6.00",
          "-5.00",
          "-4.00",
          "-3.00",
          "-2.00",
          "-1.00",
          "0.00",
          "1.00",
          "2.00",
          "3.00",
          "4.00",
          "5.00",
          "6.00",
          "7.00",
          "8.00",
          "9.00",
          "10.00",
          "11.00",
          "12.00",
          "13.00",
          "14.00",
          "15.00",
          "16.00",
          "17.00",
          "18.00",
          "19.00",
          "20.00",
          "21.00",
          "22.00",
          "23.00",
          "24.00",
          "25.00",
          "26.00",
          "27.00",
          "28.00",
          "29.00",
          "30.00",
          "31.00",
          "32.00",
          "33.00",
          "34.00",
          "35.00",
          "36.00",
          "37.00",
          "38.00",
          "39.00",
          "40.00",
          "41.00",
          "42.00",
          "43.00",
          "44.00",
          "45.00",
          "46.00",
          "47.00",
          "48.00",
          "49.00"
        ],
        formatter: "0,0.00",
        fieldType: "plain",
        fieldMetaData: null
      }
    ],
    dataSources: [],
    dataSourceProvider: { id: "fixan" },
    readOnly: false
  }
};
