import React from "react";
import ReactDOM from "react-dom";
import qs from "query-string";
import { observer } from "mobx-react-lite";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { Grommet, Box, Text, Grid } from "grommet";
import Dashboard from "./components/Dashboard";
import Document from "./components/Document";
import CreateDocument from "./components/CreateDocument";
import SharedDocument from "./components/SharedDocument";
import LoginPage from "./components/LoginPage";
import { theme } from "./misc/styles";
import { prepareRoute, ROUTES } from "./misc/routes";
import {
  initialRootSnapshot,
  RootStore,
  StoreContext,
  useStore
} from "./store";
import * as serviceWorker from "./serviceWorker";
import Header from "./components/Header";
import "./index.css";
import authProvider from "./authProvider3";
import dataSource from "./dataSource3";

export const store = RootStore.create(initialRootSnapshot);
store.setProviders({ authProvider, dataSource });
store.userStore.checkAuth();

window.storex = store;

const Main = observer(function() {
  const location = useLocation();
  const { userStore } = useStore();
  const { px } = userStore;
  const { from } = location.state || { from: { pathname: ROUTES.root } };

  if (!px.checkAuth.isFinalized) {
    return (
      <Box pad="large" background="light-2">
        <Text textAlign="center">loading...</Text>
      </Box>
    );
  }

  return (
    <Grid fill="vertical" areas={[["header"], ["main"]]} columns={["flex"]}>
      <Box gridArea="header">
        <Header />
      </Box>
      <Box gridArea="main" background="light-2">
        <Switch>
          <PrivateRoute exact path={ROUTES.root}>
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path={ROUTES.createDoc}>
            <CreateDocument />
          </PrivateRoute>
          <PrivateRoute path={ROUTES.doc}>
            <Document />
          </PrivateRoute>
          <Route path={ROUTES.sharedDoc}>
            <SharedDocument />
          </Route>
          <PrivateRoute path={[ROUTES.gdriveCreate, ROUTES.gdriveOpen]}>
            <GDriveHandler />
          </PrivateRoute>
          <Route path={ROUTES.signIn}>
            {userStore.isAuthenticated ? <Redirect to={from} /> : <LoginPage />}
          </Route>
        </Switch>
      </Box>
    </Grid>
  );
});

const GDriveHandler = observer(function() {
  const location = useLocation();
  const { action, ids, folderId } =
    JSON.parse(qs.parse(location.search).state || null) || {};

  if (action === "open") {
    return <Redirect to={prepareRoute(ROUTES.doc, { docId: ids[0] })} />;
  }

  return (
    <Redirect
      to={`${ROUTES.createDoc}${folderId ? `?folderId=${folderId}` : ""}`}
    />
  );
});

// ToDo: handle when "render" passed instead of children
const PrivateRoute = observer(function({ render, children, ...rest }) {
  const location = useLocation();
  const { userStore } = useStore();

  return (
    <Route {...rest}>
      {userStore.isAuthenticated ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: ROUTES.signIn,
            state: { from: location }
          }}
        />
      )}
    </Route>
  );
});

function Root() {
  return (
    <StoreContext.Provider value={store}>
      <Grommet theme={theme}>
        <Router>
          <Main />
        </Router>
      </Grommet>
    </StoreContext.Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
