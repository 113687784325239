import { base } from "grommet/themes";

export const theme = {
  global: {
    colors: {
      brand: base.global.colors["dark-1"],
      background: "#fff"
    }
  }
};

export const docWidth = "52rem";
