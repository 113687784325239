import React, { useEffect } from "react";
import qs from "query-string";
import { observer } from "mobx-react-lite";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Text } from "grommet";
import { useStore } from "../store";
import { prepareRoute, ROUTES } from "../misc/routes";
import * as samples from "./samples";
import blankTemplate from "./blankTemplate";

/**
 * @return {null}
 */
function CreateDocument() {
  const location = useLocation();
  const history = useHistory();
  const { documentStore } = useStore();
  const { folderId, tmpl } = qs.parse(location.search) || {};
  const metaData = folderId && {
    parents: [folderId]
  };

  useEffect(() => {
    const createDocument = async () => {
      const doc = await documentStore.createDocument(
        metaData,
        samples[tmpl] || blankTemplate
      );
      history.push(prepareRoute(ROUTES.doc, { docId: doc.id }));
    };

    createDocument();
  }, []); // eslint-disable-line

  if (documentStore.px.createDocument.isPending) {
    return (
      <Box pad="large">
        <Text textAlign="center">Creating document...</Text>
      </Box>
    );
  }

  if (documentStore.px.createDocument.isRejected) {
    return (
      <Box pad="large">
        <Text textAlign="center">Unexpected error occurred.</Text>
      </Box>
    );
  }

  return null;
}

export default observer(CreateDocument);
