import React, { useEffect } from "react";
import { get } from "lodash-es";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Box, Text, Heading } from "grommet";
import { useStore } from "../store";
import { docWidth } from "../misc/styles";
import { nameWithoutExtension } from "../misc/utils";
import Editor from "./Editor";

function SharedDocument() {
  const { docId } = useParams();
  const store = useStore();
  const { documentStore } = store;
  const { sharedDocument, px } = documentStore;

  useEffect(() => {
    documentStore.fetchSharedDocument(docId);
  }, [docId, documentStore]);

  if (px.fetchSharedDocument.isPending) {
    return (
      <Box pad="large">
        <Text textAlign="center">loading...</Text>
      </Box>
    );
  }

  if (px.fetchSharedDocument.isRejected) {
    return (
      <Box pad="large">
        <Text textAlign="center">
          {get(px.fetchSharedDocument.error, "error.code") === 404
            ? "Document not found or you don't have required permissions to view."
            : "Unexpected error occurred."}
        </Text>
      </Box>
    );
  }

  if (!sharedDocument) {
    return null;
  }

  const sharedBy = get(sharedDocument, "owners[0].displayName");

  return (
    <Box
      fill="vertical"
      background="light-2"
      align="center"
      pad={{ vertical: "medium" }}
    >
      <Box fill="horizontal" width={{ max: docWidth }}>
        <Box
          direction="row"
          align="baseline"
          justify="between"
          margin={{ bottom: "small" }}
        >
          <Box direction="row" align="baseline">
            <Text margin={{ right: "xsmall" }}>title:</Text>
            <Heading margin="none" level="3">
              {nameWithoutExtension(sharedDocument.name)}
            </Heading>
          </Box>
          {sharedBy && (
            <Box direction="row">
              <Text size="small">shared by:</Text>
              <Text size="small" margin={{ left: "xxsmall" }} weight="bold">
                {get(sharedDocument, "owners[0].displayName")}
              </Text>
            </Box>
          )}
        </Box>
        <Box border={{ color: "light-5" }}>
          <Editor appStore={store} value={sharedDocument.content} readOnly />
        </Box>
      </Box>
    </Box>
  );
}

export default observer(SharedDocument);
