import { get, isNil } from "lodash-es";
import { store } from "./index";
import { DOC_MIME_TYPE, DOC_FILE_EXTENSION_WITH_DOT } from "./misc/common";

export const apiUrl = "https://www.googleapis.com/drive/v3";

export const API_KEY = "AIzaSyBoYGCBVfCuAlEcpK_zsbn_ClHdwHuUwuQ";
export const PROJECT_NUMBER = "1043917107049";

// ToDo: optimise fields for different endpoints
export const commonFileFieldsArray = [
  "id",
  "name",
  "modifiedTime",
  "permissions",
  "owners"
];
export const commonFileFieldsString = commonFileFieldsArray.join(",");
export const PERMISSIONS = {
  anyone: "anyone",
  anyoneWithLink: "anyoneWithLink"
};
const keyParam = "&key=" + API_KEY;

const showPickerDoc = () => {
  return new Promise(resolve => {
    const picker = window.gapi.picker.api;
    const view = new picker.DocsView();
    view.setIncludeFolders(true);
    view.setMimeTypes(DOC_MIME_TYPE.google);
    view.setMode(picker.DocsViewMode.LIST);
    new picker.PickerBuilder()
      .setAppId(PROJECT_NUMBER)
      .setOAuthToken(store.userStore.user.access_token)
      .setDeveloperKey(API_KEY)
      .setCallback(data => {
        if (data.action === "loaded") {
          return;
        }

        resolve(data);
      })
      .addView(view)
      .build()
      .setVisible(true);
  });
};

const showPickerFolder = () => {
  return new Promise(resolve => {
    const picker = window.gapi.picker.api;
    const view = new picker.DocsView();
    view.setIncludeFolders(true);
    view.setSelectFolderEnabled(true);
    view.setMimeTypes("application/vnd.google-apps.folder");
    view.setMode(picker.DocsViewMode.LIST);
    new picker.PickerBuilder()
      .setAppId(PROJECT_NUMBER)
      .setOAuthToken(store.userStore.user.access_token)
      .setDeveloperKey(API_KEY)
      .setCallback(data => {
        if (data.action === "loaded") {
          return;
        }

        resolve(data);
      })
      .addView(view)
      .setTitle("Select destination folder")
      .build()
      .setVisible(true);
  });
};

export default {
  PERMISSIONS,
  getDocuments: async () => {
    const url = `${apiUrl}/files?fields=files(${commonFileFieldsString})&q=mimeType='${
      DOC_MIME_TYPE.google
    }' and trashed = false`;

    const request = await fetch(url, {
      headers: {
        Authorization: `Bearer ${store.userStore.user.access_token}`
      }
    });
    const { files } = await request.json();

    return files;
  },
  getDocument: async (id, isShared) => {
    const extraHeaders = isShared
      ? undefined
      : {
          headers: {
            Authorization: `Bearer ${store.userStore.user.access_token}`
          }
        };
    const extraParams = isShared ? keyParam : "";
    const urlMetaData = `${apiUrl}/files/${id}?fields=${commonFileFieldsString}${extraParams}`;
    const urlFile = `${apiUrl}/files/${id}?alt=media${extraParams}`;

    // ToDo: is try-catch necessary? might be better to catch error in store
    try {
      const requestMetaData = await fetch(urlMetaData, extraHeaders);
      const metaData = (await requestMetaData.json()) || {};

      const requestFile = await fetch(urlFile, extraHeaders);
      const text = await requestFile.text();
      const content = isNil(text) || text === "" ? null : JSON.parse(text);

      if (!isNil(content) && !!content.error) {
        return Promise.reject(content);
      }

      return Promise.resolve({
        ...metaData,
        content
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  createDocument: async (_metaData, data) => {
    const metaData = {
      name: `Untitled${DOC_FILE_EXTENSION_WITH_DOT}`,
      ..._metaData
    };
    const content = isNil(data) ? "" : JSON.stringify(data);
    const boundary = "END_OF_PART";
    const body = `
--${boundary}
Content-Type: application/json; charset=UTF-8

${JSON.stringify(metaData)}

--${boundary}
Content-Type: ${DOC_MIME_TYPE.google}

${content}
--${boundary}--
    `;
    const request = await fetch(
      `https://www.googleapis.com/upload/drive/v3/files?fields=${commonFileFieldsString}&uploadType=multipart`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": `multipart/related; boundary=${boundary}`,
          "Content-Length": content.length,
          Authorization: `Bearer ${store.userStore.user.access_token}`
        }),
        body
      }
    );

    return await request.json();
  },
  updateDocument: async (docId, data = {}) => {
    const stringified = JSON.stringify(data);
    const request = await fetch(
      `https://www.googleapis.com/upload/drive/v3/files/${docId}?fields=${commonFileFieldsString}`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": DOC_MIME_TYPE.google,
          Authorization: `Bearer ${store.userStore.user.access_token}`
        }),
        body: stringified
      }
    );

    return await request.json();
  },
  renameDocument: async (docId, name) => {
    const boundary = "END_OF_PART";
    const body = `
--${boundary}
Content-Type: application/json; charset=UTF-8

{
  "name": "${name.trim()}"
}
--${boundary}--
    `;
    const request = await fetch(
      `https://www.googleapis.com/upload/drive/v3/files/${docId}?fields=${commonFileFieldsString}&uploadType=multipart`,
      {
        method: "PATCH",
        headers: new Headers({
          "Content-Type": `multipart/related; boundary=${boundary}`,
          Authorization: `Bearer ${store.userStore.user.access_token}`
        }),
        body
      }
    );

    return await request.json();
  },
  createPermission: async docId => {
    const request = await fetch(
      `https://www.googleapis.com/drive/v3/files/${docId}/permissions`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${store.userStore.user.access_token}`
        }),
        body: JSON.stringify({
          type: "anyone",
          role: "reader"
        })
      }
    );

    return await request.json();
  },
  deletePermission: async (docId, permissionId) => {
    return await fetch(
      `https://www.googleapis.com/drive/v3/files/${docId}/permissions/${permissionId}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: `Bearer ${store.userStore.user.access_token}`
        })
      }
    );
  },
  pickDocument: async () => {
    const response = await showPickerDoc();
    return Promise.resolve(get(response, "docs[0].id"));
  },
  pickFolder: async () => {
    const response = await showPickerFolder();
    return Promise.resolve(get(response, "docs[0].id"));
  }
};
