import React, { Component, Fragment } from "react";
import { trimStart, isEmpty, endsWith } from "lodash-es";
import { Box } from "grommet";
import { EditorState } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { Schema } from "prosemirror-model";
import { addListNodes } from "prosemirror-schema-list";
import { schema } from "prosemirror-schema-basic";
import { exampleSetup, buildMenuItems } from "prosemirror-example-setup";
import {
  buildPlugin,
  buildMenuItems as rpBuildMenuItems,
  menuItems,
  nodes,
  NODES_NAMES
} from "@reactivepad/prosemirror";
import { ThemeProvider } from "@zendeskgarden/react-theming";
import { Dropdown, Menu, Item, Trigger } from "@zendeskgarden/react-dropdowns";
import "./prosemirror.css";

export const formulaSchema = new Schema({
  nodes: addListNodes(schema.spec.nodes, "paragraph block*", "block").append(
    nodes
  ),
  marks: schema.spec.marks
});

const CONTEXT_MENU_TRIGGER = "@";

class Editor extends Component {
  static defaultProps = {
    readOnly: false,
    onChange: () => {}
  };

  state = {
    store: null,
    menuPosition: null
  };

  componentDidMount() {
    const menu = buildMenuItems(formulaSchema);
    menu.blockMenu[0].splice(menu.blockMenu[0].length - 1, 1);

    const rpMenu = rpBuildMenuItems(
      menuItems.map(menuItem => ({
        ...menuItem,
        label: trimStart(menuItem.title, "Insert").trim(),
        class: "rxp-menu-item"
      }))
    );
    menu.fullMenu.unshift(rpMenu);

    const { appStore, value, onChange, readOnly } = this.props;
    const reactivepadPlugin = buildPlugin({
      authProvider: appStore.authProvider.tempProvider
    });

    const plugins = [
      ...exampleSetup({
        schema: formulaSchema,
        menuBar: !readOnly,
        menuContent: menu.fullMenu
      }),
      reactivepadPlugin.plugin
    ];

    let editorState;
    if (value && !isEmpty(value)) {
      editorState = EditorState.fromJSON(
        {
          schema: formulaSchema,
          plugins
        },
        value,
        { [reactivepadPlugin.plugin.key]: reactivepadPlugin.plugin }
      );
    } else {
      editorState = EditorState.create({
        schema: formulaSchema,
        plugins
      });
    }

    const editorView = new EditorView(this.editorRef, {
      state: editorState,
      handleTextInput: (view, from, to, text) => {
        if (text === CONTEXT_MENU_TRIGGER) {
          const menuPosition = view.coordsAtPos(from);
          this.setState({ menuPosition });
        } else if (!!this.state.menuPosition) {
          this.setState({ menuPosition: null });
        }
      },
      dispatchTransaction: transaction => {
        editorView.updateState(editorView.state.apply(transaction));

        const pluginKey = reactivepadPlugin.plugin.key;
        const rxpPlugin = editorView.state.plugins.find(
          ({ key }) => key === pluginKey
        );
        const doc = editorView.state.toJSON({
          [pluginKey]: rxpPlugin
        });

        onChange(doc);
      },
      attributes: {
        spellcheck: "false"
      },
      editable: () => !readOnly
    });

    this.timeoutId = setTimeout(() => {
      this.setState({ store: reactivepadPlugin.getStore() });
    }, 1);
    this.editorView = editorView;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    this.editorView && this.editorView.destroy();
  }

  handleContextMenuSelect = selectedKey => {
    const { state, dispatch } = this.editorView;
    const { $from, $to } = state.selection;

    menuItems
      .find(item => item.key === selectedKey)
      .run(state, tr => {
        if (
          $from.pos === $to.pos &&
          endsWith($to.nodeBefore.text, CONTEXT_MENU_TRIGGER)
        ) {
          if (
            $to.parent.isBlock &&
            $to.parent.textContent.length === 1 &&
            selectedKey !== NODES_NAMES.formula
          ) {
            dispatch(tr.delete($to.pos - 2, $to.pos + 1));
          } else {
            dispatch(tr.delete($to.pos - 1, $to.pos));
          }
        } else {
          dispatch(tr);
        }
      });
    this.setState({ menuPosition: null });
  };

  handleEditorRef = el => {
    this.editorRef = el;
  };

  render() {
    const { menuPosition } = this.state;
    const { className, style } = this.props;
    const contextMenuTriggerStyles = {
      position: "fixed",
      ...(!!menuPosition
        ? {
            display: "inline",
            top: menuPosition.top + 20,
            left: menuPosition.left
          }
        : {
            display: "none"
          })
    };

    return (
      <Fragment>
        <Box
          fill="vertical"
          background="background"
          className={className}
          style={style}
          ref={this.handleEditorRef}
        />
        <ThemeProvider>
          <Dropdown
            isOpen={!!menuPosition}
            onSelect={this.handleContextMenuSelect}
          >
            <Trigger>
              <span style={contextMenuTriggerStyles} />
            </Trigger>
            <Menu placement="bottom" arrow isAnimated={false}>
              <Item value={NODES_NAMES.formula}>Insert formula</Item>
              <Item value={NODES_NAMES.table}>Insert table</Item>
            </Menu>
          </Dropdown>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Editor;
