export default {
  doc: {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: [
          { type: "text", text: "Hello & welcome to Reactivepad Beta!" },
          { type: "hard_break" },
          { type: "hard_break" },
          { type: "text", text: "Thanks for using our product. :) " },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "You can choose one of the templates that we prepared (and play with them) or create your own document. "
          },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "Here we collected some of the most important topics to start using Reactivepad. "
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "If you have any questions, catch us via: hello@reactivepad.com or book a short call here:"
          },
          { type: "hard_break" },
          {
            type: "text",
            marks: [
              {
                type: "link",
                attrs: {
                  href: "https://calendly.com/reactivepad/reactivepad-demo",
                  title: "demo call"
                }
              }
            ],
            text: "https://calendly.com/reactivepad/reactivepad-demo"
          },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "How to add a formula"
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "To insert a formula, choose “Formula” from the menu or press @."
          },
          { type: "hard_break" },
          {
            type: "text",
            text: "You can create a new formula with the variables you want."
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "You can also choose one of the existing, using the formula bank that we have built into the product. In the “Value” field, simply insert = and then the formula you wnat to use, for example “= SUM”."
          },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "Changing the name of the formula "
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "In Reactivepad, everything updates automatically. When you find that you want to name the formula differently, you don't have to worry about the places where you referred to it. The name will change immediately and the data will be correct all the time."
          },
          { type: "hard_break" },
          { type: "hard_break", marks: [{ type: "strong" }] },
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "How to create a table"
          },
          { type: "hard_break", marks: [{ type: "strong" }] },
          {
            type: "text",
            text:
              "As you can see, we have tables feature as well. Same as with formulas, you can choose it from the nav bar, or by pressing @ symbol."
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "It’s important to name your table (the field under the table), and then you can use it as you want. "
          },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "Calculations in tables"
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "Not only formulas can take part in calculations but also tables. Tables are quite similar to spreadsheets but are more concise and readable. Data selection is not coordinates based (A1:G5) but relies on names of tables and it's columns."
          },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "References to other values"
          },
          { type: "text", text: " " },
          { type: "hard_break" },
          {
            type: "text",
            text:
              "When you have some formula created, you can easily refer to it by creating or editing another formula. You will find it in the formula editing a drop-down list. You can use it then for further calculations."
          },
          { type: "hard_break" },
          { type: "hard_break" },
          { type: "text", marks: [{ type: "strong" }], text: "Column formula" },
          { type: "text", text: " " },
          { type: "hard_break" },
          {
            type: "text",
            text:
              'Columns can be computed with formulas. Select the column in which you want to create the formula and hover over its name. Then a drop-down menu will appear with the "formula" option. It can be both a formula format or a plain value. Start with = sign if you want to calculate, or just insert a plain value. Note that cells in computed columns are not editable since they show the result of the calculation.'
          },
          { type: "hard_break" },
          { type: "hard_break" },
          {
            type: "text",
            marks: [{ type: "strong" }],
            text: "Columns aggregations in the footer"
          },
          { type: "hard_break" },
          {
            type: "text",
            text:
              'In many cases, a column in a table needs to be aggregated, i.e. the total price of an order. The perfect place for that is table footer. Right-click on a table to trigger context menu and click "Show footer". Then you can choose one of the aggregation functions from the drop-down.'
          },
          { type: "hard_break" },
          { type: "hard_break" },
          { type: "text", text: "Enjoy!" },
          { type: "hard_break" },
          { type: "hard_break" },
          { type: "text", text: "Have a great day," },
          { type: "hard_break" },
          { type: "text", text: "Reactivepad Team" },
          { type: "hard_break" }
        ]
      }
    ]
  },
  selection: { type: "text", anchor: 2569, head: 2569 },
  reactivepad_prosemirror$: {
    id: "boyam",
    nodes: [],
    dataSources: [],
    dataSourceProvider: null,
    readOnly: false
  }
};
