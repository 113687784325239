import { trimEnd } from "lodash-es";
import nanoidGenerate from "nanoid/generate";
import nanoidDictionary from "nanoid-dictionary/lowercase";
import { DateTime } from "luxon";
import { DOC_FILE_EXTENSION_WITH_DOT } from "./common";

export const generateId = (length = 5) =>
  nanoidGenerate(nanoidDictionary, length);

export const formatDate = date =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);

export const nameWithoutExtension = name =>
  trimEnd(name, DOC_FILE_EXTENSION_WITH_DOT);

export const nameWithExtension = name =>
  `${name}${DOC_FILE_EXTENSION_WITH_DOT}`;
