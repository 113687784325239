export const ASYNC_STATES = {
  NONE: "NONE",
  PENDING: "PENDING",
  RESOLVED: "RESOLVED",
  REJECTED: "REJECTED"
};

export const DOC_FILE_EXTENSION = "rxp";
export const DOC_FILE_EXTENSION_WITH_DOT = `.${DOC_FILE_EXTENSION}`;

export const DOC_MIME_TYPE = {
  google: `application/vnd.reactivepad.${DOC_FILE_EXTENSION}`
};
