import React, { useEffect } from "react";
import { Box, List, Text, Heading } from "grommet";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../store";
import { nameWithoutExtension } from "../misc/utils";
import { prepareRoute, ROUTES } from "../misc/routes";

function DocumentList() {
  const history = useHistory();
  const { documentStore } = useStore();
  const { documents, px } = documentStore;

  useEffect(() => {
    documentStore.fetchDocuments();
  }, [documentStore]);

  if (px.fetchDocuments.isPending) {
    return <Box pad="medium">loading...</Box>;
  }

  if (documents.length === 0) {
    return (
      <Box pad={{ vertical: "small" }}>
        <Text color="dark-4">
          <i>You don't have any docs at the moment.</i>
        </Text>
      </Box>
    );
  }

  const mappedDocuments = documents.map(d => ({
    id: d.id,
    name: d.name,
    modifiedTime: d.modifiedTimeFormatted
  }));

  return (
    <List
      background="background"
      border={{ side: "horizontal", color: "light-2" }}
      data={mappedDocuments}
      primaryKey={({ name, modifiedTime }) => (
        <Box>
          <Heading level="5" margin="none">
            {nameWithoutExtension(name)}
          </Heading>
          <Text size="small" color="dark-5">
            {modifiedTime}
          </Text>
        </Box>
      )}
      onClickItem={({ item }) => {
        history.push(prepareRoute(ROUTES.doc, { docId: item.id }));
      }}
    />
  );
}

export default observer(DocumentList);
