import { createContext, useContext } from "react";
import { values } from "lodash-es";
import {
  types,
  flow,
  applySnapshot,
  getRoot,
  getParentOfType
} from "mobx-state-tree";
import { ASYNC_STATES } from "../misc/common";
import { formatDate } from "../misc/utils";
import authProvider from "../authProvider";
import dataSource from "../dataSource";

export const StoreContext = createContext();

export function useStore() {
  return useContext(StoreContext);
}

export const AsyncStateModel = types
  .model("AsyncStateModel", {
    state: types.optional(
      types.enumeration(values(ASYNC_STATES)),
      ASYNC_STATES.NONE
    ),
    error: types.frozen(null)
  })
  .views(self => ({
    get isNone() {
      return self.state === ASYNC_STATES.NONE;
    },
    get isPending() {
      return self.state === ASYNC_STATES.PENDING;
    },
    get isResolved() {
      return self.state === ASYNC_STATES.RESOLVED;
    },
    get isRejected() {
      return self.state === ASYNC_STATES.REJECTED;
    },
    get isFinalized() {
      return self.isResolved || self.isRejected;
    }
  }))
  .actions(self => ({
    setNone: () => (self.state = ASYNC_STATES.NONE),
    setPending: () => {
      self.reset();
      self.state = ASYNC_STATES.PENDING;
    },
    setResolved: () => (self.state = ASYNC_STATES.RESOLVED),
    setRejected: error => {
      self.state = ASYNC_STATES.REJECTED;
      self.error = error;
    },
    reset: () => {
      self.setNone();
      self.error = null;
    }
  }));

export const Document = types
  .model("Document", {
    id: types.identifier,
    name: types.string,
    modifiedTime: types.string,
    content: types.frozen(null),
    permissions: types.array(types.frozen()),
    owners: types.array(types.frozen())
  })
  .views(self => ({
    get documentStore() {
      return getParentOfType(self, DocumentStore);
    },
    get sharingPermission() {
      return self.permissions.find(
        ({ id }) =>
          id === self.documentStore.dataSource.PERMISSIONS.anyoneWithLink
      );
    },
    get isShared() {
      return !!self.sharingPermission;
    },
    get modifiedTimeFormatted() {
      return formatDate(self.modifiedTime);
    }
  }))
  .actions(self => ({
    setName(name) {
      self.name = name;
    },
    setContent(content) {
      self.content = content;
    },
    addPermission(permission) {
      if (self.permissions.some(({ id }) => id === permission.id)) {
        return;
      }

      self.permissions.push(permission);
    },
    removePermission(permission) {
      self.permissions = self.permissions.filter(
        ({ id }) => id !== permission.id
      );
    }
  }));

export const DocumentStore = types
  .model("DocumentStore", {
    documents: types.array(Document),
    selectedDocument: types.maybeNull(Document),
    sharedDocument: types.maybeNull(Document)
  })
  .volatile(() => ({
    px: {
      fetchDocuments: AsyncStateModel.create(),
      fetchDocument: AsyncStateModel.create(),
      createDocument: AsyncStateModel.create(),
      updateDocument: AsyncStateModel.create(),
      renameDocument: AsyncStateModel.create(),
      createPermission: AsyncStateModel.create(),
      deletePermission: AsyncStateModel.create(),
      fetchSharedDocument: AsyncStateModel.create()
    }
  }))
  .views(self => ({
    get store() {
      return getRoot(self);
    },
    get dataSource() {
      return self.store.dataSource;
    },
    findDocumentById(id) {
      return self.documents.find(d => d.id === id);
    }
  }))
  .actions(self => ({
    fetchDocuments: flow(function*() {
      self.px.fetchDocuments.setPending();
      try {
        self.documents = yield self.dataSource.getDocuments();
        self.px.fetchDocuments.setResolved();
      } catch (e) {
        self.px.fetchDocuments.setRejected(e);
      }
    }),
    fetchDocument: flow(function*(id) {
      self.px.fetchDocument.setPending();
      try {
        self.selectedDocument = Document.create(
          yield self.dataSource.getDocument(id)
        );
        self.px.fetchDocument.setResolved();
      } catch (e) {
        self.px.fetchDocument.setRejected(e);
      }
    }),
    fetchSharedDocument: flow(function*(id) {
      self.px.fetchSharedDocument.setPending();
      try {
        self.sharedDocument = Document.create(
          yield self.dataSource.getDocument(id, true)
        );
        self.px.fetchSharedDocument.setResolved();
      } catch (e) {
        self.px.fetchSharedDocument.setRejected(e);
      }
    }),
    createDocument: flow(function*(metaData, data) {
      self.px.createDocument.setPending();
      try {
        // ToDo: fix this
        self.selectedDocument = null;
        const responseData = yield self.dataSource.createDocument(
          metaData,
          data
        );
        self.px.createDocument.setResolved();
        return responseData;
      } catch (e) {
        self.px.createDocument.setRejected(e);
      }
    }),
    updateDocument: flow(function*(doc, data) {
      self.px.updateDocument.setPending();
      try {
        yield self.dataSource.updateDocument(doc.id, data);
        self.px.updateDocument.setResolved();
      } catch (e) {
        self.px.updateDocument.setRejected(e);
      }
    }),
    renameDocument: flow(function*(doc, name) {
      self.px.renameDocument.setPending();
      try {
        yield self.dataSource.renameDocument(doc.id, name);
        self.px.renameDocument.setResolved();
      } catch (e) {
        self.px.renameDocument.setRejected(e);
      }
    }),
    createPermission: flow(function*(
      doc,
      permissionId = self.dataSource.PERMISSIONS.anyoneWithLink
    ) {
      self.px.createPermission.setPending();
      try {
        const permission = yield self.dataSource.createPermission(
          doc.id,
          permissionId
        );
        doc.addPermission(permission);
        self.px.createPermission.setResolved();
      } catch (e) {
        self.px.createPermission.setRejected(e);
      }
    }),
    deletePermission: flow(function*(
      doc,
      permissionId = self.dataSource.PERMISSIONS.anyoneWithLink
    ) {
      self.px.deletePermission.setPending();
      try {
        yield self.dataSource.deletePermission(doc.id, permissionId);
        doc.removePermission({ id: permissionId });
        self.px.deletePermission.setResolved();
      } catch (e) {
        self.px.deletePermission.setRejected(e);
      }
    }),
    pickDocument: flow(function*(...args) {
      try {
        return yield self.dataSource.pickDocument(...args);
      } catch (e) {
        return Promise.reject(e);
      }
    }),
    pickFolder: flow(function*(...args) {
      try {
        return yield self.dataSource.pickFolder(...args);
      } catch (e) {
        return Promise.reject(e);
      }
    })
  }));

export const UserStore = types
  .model("UserStore", {
    user: types.frozen(null)
  })
  .volatile(() => ({
    px: {
      login: AsyncStateModel.create(),
      logout: AsyncStateModel.create(),
      checkAuth: AsyncStateModel.create()
    }
  }))
  .views(self => ({
    get store() {
      return getRoot(self);
    },
    get authProvider() {
      return self.store.authProvider;
    },
    get isAuthenticated() {
      return !!self.user;
    }
  }))
  .actions(self => ({
    setUser: function(user) {
      self.user = user;
    },
    login: flow(function*(...args) {
      self.px.login.setPending();
      try {
        self.user = yield self.authProvider.login(...args);
        self.px.login.setResolved();
      } catch (e) {
        self.px.login.setRejected(e);
      }
    }),
    logout: flow(function*() {
      self.px.logout.setPending();
      try {
        yield self.authProvider.logout();
        self.store.reset();
      } catch (e) {
        self.px.logout.setRejected(e);
      }
    }),
    checkAuth: flow(function*() {
      self.px.checkAuth.setPending();
      try {
        self.user = yield self.authProvider.checkAuth();
        self.px.checkAuth.setResolved();
      } catch (e) {
        self.px.checkAuth.setRejected(e);
      }
    })
  }));

export const initialRootSnapshot = {};

export const RootStore = types
  .model("RootStore", {
    documentStore: types.optional(DocumentStore, {}),
    userStore: types.optional(UserStore, {})
  })
  .volatile(() => ({
    authProvider,
    dataSource
  }))
  .actions(self => ({
    setAuthProvider(authProvider) {
      self.authProvider = authProvider;
    },
    setDataSource(dataSource) {
      self.dataSource = dataSource;
    },
    setProviders({ authProvider, dataSource } = {}) {
      self.authProvider = authProvider;
      self.dataSource = dataSource;
    },
    reset() {
      applySnapshot(self, initialRootSnapshot);
    }
  }));
