import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "grommet";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useStore } from "../store";
import { firebaseAuth } from "../authProvider3";

function LoginPage() {
  const store = useStore();

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: ["anonymous", firebase.auth.EmailAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: ({ user }) => {
        store.userStore.setUser(user.toJSON());
        return false; // Avoid redirects after sign-in
      }
    }
  };

  return (
    <Box gridArea="content" background="light-2" fill="vertical" align="center">
      <Box pad={{ top: "medium" }}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
      </Box>
    </Box>
  );
}

export default observer(LoginPage);
