import firebaseApp from "firebase/app";
import "firebase/auth";

export const config = {
  apiKey: "AIzaSyDd7HZrSlbxazchtnWidpZHLsvUCJA8bME",
  authDomain: "reactivepad-webapp-test.firebaseapp.com",
  projectId: "reactivepad-webapp-test",
  storageBucket: "reactivepad-webapp-test.appspot.com",
  messagingSenderId: "521619854111",
  appId: "1:521619854111:web:b79f7b384cad761196562a"
};

export const firebase = firebaseApp.initializeApp(config);

export const firebaseAuth = firebaseApp.auth();

export default {
  login: (email = "test@test.com", password = "testpass") => {
    return firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => user.toJSON());
  },
  logout: () => {
    return firebaseAuth.signOut();
  },
  checkAuth: () => {
    return new Promise((resolve, reject) => {
      const disposer = firebaseAuth.onAuthStateChanged(auth => {
        disposer();
        if (auth) {
          resolve(auth.toJSON());
        } else {
          reject(new Error("checkAuth error"));
        }
      });
    });
  }
};
