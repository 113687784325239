import React, { useEffect, useState } from "react";
import { debounce, get } from "lodash-es";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  // CheckBox,
  FormField,
  TextInput,
  Text,
  Layer
} from "grommet";
// import { Link } from "grommet-icons";
import { useStore } from "../store";
import Editor from "./Editor";
import { prepareRoute, ROUTES } from "../misc/routes";
import { nameWithExtension, nameWithoutExtension } from "../misc/utils";
import { docWidth } from "../misc/styles";

function Document() {
  const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
  const { docId } = useParams();
  const store = useStore();
  const { documentStore } = store;
  const { selectedDocument, px } = documentStore;

  useEffect(() => {
    documentStore.fetchDocument(docId);
  }, [docId]); // eslint-disable-line

  if (px.fetchDocument.isPending) {
    return (
      <Box pad="large">
        <Text textAlign="center">loading...</Text>
      </Box>
    );
  }

  if (px.fetchDocument.isRejected) {
    return (
      <Box pad="large">
        <Text textAlign="center">
          {get(px.fetchDocument.error, "error.code") === 404
            ? "Document not found."
            : "Unexpected error occurred."}
        </Text>
      </Box>
    );
  }

  if (!selectedDocument) {
    return null;
  }

  /*
  const handleSharingToggled = ({ target }) => {
    if (target.checked) {
      documentStore.createPermission(selectedDocument);
    } else {
      documentStore.deletePermission(selectedDocument);
    }
  };
  */

  const handleTitleChangeDebounced = debounce(value => {
    selectedDocument.setName(value);
    documentStore.renameDocument(selectedDocument, nameWithExtension(value));
  }, 500);

  const handleEditorChange = debounce(content => {
    documentStore.updateDocument(selectedDocument, content);
  }, 500);

  const shareLink = `${window.location.origin}${prepareRoute(ROUTES.sharedDoc, {
    docId
  })}`;

  return (
    <Box
      fill="vertical"
      background="light-2"
      align="center"
      pad={{ vertical: "medium" }}
    >
      <Box fill="horizontal" width={{ max: docWidth }}>
        <Box
          flex
          direction="row"
          align="end"
          justify="between"
          fill="horizontal"
          margin={{ bottom: "xsmall" }}
        >
          <FormField
            help={<Text size="small">title:</Text>}
            htmlFor="doc-name-input"
          >
            <TextInput
              id="doc-name-input"
              placeholder="title"
              defaultValue={nameWithoutExtension(selectedDocument.name)}
              onChange={({ target }) =>
                handleTitleChangeDebounced(target.value)
              }
              spellCheck={false}
              maxLength={20}
            />
          </FormField>
          {/*<Box pad={{ bottom: "small" }} direction="row" alignContent="center">
            <CheckBox
              checked={selectedDocument.isShared}
              label="shared"
              onChange={handleSharingToggled}
              toggle
            />
            <Button
              disabled={!selectedDocument.isShared}
              margin={{ left: "small" }}
              primary
              label="Get link"
              size="small"
              icon={<Link />}
              onClick={() => setShareLinkModalOpen(true)}
            />
          </Box>*/}
        </Box>
        <Box
          border={{ color: "light-5" }}
          height={{ min: "70vh" }}
          background="background"
        >
          <Editor
            appStore={store}
            value={selectedDocument.content}
            onChange={handleEditorChange}
          />
        </Box>
        {shareLinkModalOpen && (
          <Layer
            position="center"
            modal
            onClickOutside={() => setShareLinkModalOpen(false)}
            onEsc={() => setShareLinkModalOpen(false)}
          >
            <Box pad="medium" gap="small" width="medium">
              <Text>Share link:</Text>
              <Box direction="row">
                <Box flex="grow" margin={{ right: "small" }}>
                  <TextInput value={shareLink} disabled />
                </Box>
                <CopyToClipboard text={shareLink}>
                  <Button label="Copy" size="small" plain />
                </CopyToClipboard>
              </Box>
              <Box
                as="footer"
                gap="small"
                direction="row"
                align="center"
                justify="end"
                pad={{ top: "medium", bottom: "small" }}
              >
                <Button
                  label="Close"
                  onClick={() => setShareLinkModalOpen(false)}
                  primary
                />
              </Box>
            </Box>
          </Layer>
        )}
      </Box>
    </Box>
  );
}

export default observer(Document);
